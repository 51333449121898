var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-1 lg:grid-cols-2" },
    [
      _c("static-text"),
      _c(
        "div",
        {
          staticClass:
            "m-0 w-full item-direction md:my-16 md:mx-auto md:w-3/5 lg:mx-0 lg:w-4/5 xl:max-w-md"
        },
        [
          _c(
            "custom-card",
            {
              staticClass: "py-4",
              attrs: {
                title: "Recuperação de Senha",
                descriptionTitle:
                  "Digite abaixo seu e-mail e clique em recuperar a senha. Você receberá um e-mail com o link de recuperação de senha."
              }
            },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var invalid = ref.invalid
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.sendRecoveryEmail()
                              }
                            }
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "E-mail",
                                rules: "required|email"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full mt-8",
                                          attrs: {
                                            type: "email",
                                            name: "email",
                                            size: "large",
                                            label: "E-mail do usuário"
                                          },
                                          model: {
                                            value: _vm.email,
                                            callback: function($$v) {
                                              _vm.email = $$v
                                            },
                                            expression: "email"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c(
                              "vs-button",
                              {
                                staticClass: "w-full mt-24 mb-12",
                                attrs: { disabled: invalid, size: "large" },
                                on: {
                                  click: function($event) {
                                    return _vm.sendRecoveryEmail()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            Enviar link de recuperação\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }